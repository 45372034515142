
body{
  height: 100%;

}

html {
  background-color: #000;
  overflow: hidden;
   position: fixed;
}
*, ::after, ::before {
    box-sizing: border-box;
}
html,body{

 overflow-y: none;
 overflow: auto;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;

 overflow: hidden;
 position: fixed;
   top: 0;
   left: 0;
   width: 100%;
}



.grey_button{
  background: linear-gradient(#cdcdcd, #6f6f6f);
}

.loadingAnimation{
  position: fixed;
  top: 50px;
  right: 10px;
  width:200px;
  height:200px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    transition: opacity .15s linear;
    background-color: #000000C0;
}


/*display: none;*/

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px);
    max-width: 500px;
    margin: 1.75rem auto;
    position: relative;
    border-radius: 20px;
}

.modal-content {

    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
    margin-top:100px;
    }


rect:clicked{
  fill:#00FF00;
}

.App {
  background-position: left top;


 background-size:cover;
  text-align: center;
  background-size: 100%;
  z-index: -1;

}

.gui_background{

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-pointer{
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none;
}


.leftbar{
  position: fixed;
  left:0px;
  top:0px;
  width:95px;
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
 scrollbar-width: none;  /* Firefox */
 overflow-y: scroll; /* has to be scroll, not auto */
 -webkit-overflow-scrolling: touch;
 overflow-x:hidden;
}

.leftbar::-webkit-scrollbar {
  display: none;
}

.leftbar::-webkit-scrollbar {
  display: none;
}






.divButton{
  border: 1px solid grey;
  border-radius: 5px;
  background-color: #AAAAAA;
  margin:4px 4px 4px 4px;
    width:85px;
    -webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
outline: 0;
}

.divButtonImg{
  margin:4px 4px 4px 4px;
  width:100px;
  -webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}



.btn_active .divButton{
  border-style: inset;
  border:solid 2px white;
}

.divglmButtonImg{
  padding:4px 4px 4px 4px;
  width:114px;
}


.gmlButtonImg{
  object-fit: cover;
}




.divButton:active{
  background-color: #606060;
}


.svgButton:active rect{
  fill: #606060;

}

.divslider{
  width:5px;
  height:50px;
  background: #000;
  z-index: 999;
  border:2px solid green;
}

.sliderContainer{
  padding-top:15px;
  padding-bottom:15px;
  padding-left: 10px;
  height:12em;
  width:50px;
}

.ipad_foreign{
  position: fixed;
  overscroll-behavior-y: none;
}
.buttonText{
  color:#FFF;
  font-size: 12px;
  white-space: nowrap;
}

.buttonTextIphone{
  color:#FFF;
  font-size: 12px;
}


.gslButton{
  display: inline-block;
}














.bottombar{
  position: fixed;
  left:120px;
  padding-right: 100px;
  bottom:0px;
  height: 105px;

  z-index: 999;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-x:scroll;
  overflow-y:hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}


.bottombar div{
  display: inline-block;
}


.top_right{
  position: fixed;
  top:0px;
  right:0px;
  height:24px;
  color:#FFF;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-top:10px;
  border-radius: 15px;
  background: #2a2a2a;
}

svg{
  overscroll-behavior-y: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

}



.sliderblue {
    opacity: 1;
    animation: fade 0.5s linear;
}


@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

















.modal-content h3{
  background-color: #3a4655;
  color:#FFFFFF;
  margin-top: 0px;
  padding-top:20px;
  padding-bottom:20px;
  padding-left:20px;
  text-align: left;
  margin-bottom: 0px;
}

.modal-days{

  background-color: #001d41;
  color:#FFFFFF;
  padding-bottom: 30px;

}

.modal-days p{
  text-align: left;
  padding-left: 20px;
}

input[type=checkbox] {
         position: relative;
	       cursor: pointer;
    }
input[type=checkbox]:before {
         content: "";
         display: block;
         position: absolute;
         width: 20px;
         height: 20px;
         top: 0;
         left: 0;
         background-color:#e9e9e9;
}
input[type=checkbox]:checked:before {
         content: "";
         display: block;
         position: absolute;
         width: 18;
         height: 18px;
         top: 0;
         left: 0;
         background-color:#1E80EF;
}
    input[type=checkbox]:checked:after {
         content: "";
         display: block;
         width: 5px;
         height: 10px;
         border: solid white;
         border-width: 0 2px 2px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         position: absolute;
         top: 2px;
         left: 6px;
}

.daysofweek{
  width:100%;
  table-layout: fixed;
}

.close_button{
  position: fixed;
  right:20px;
  width:30px;
  height:30px;
  border-radius: 30px;
  border:none;
  background-color: #AAAAAA;
}


.time_input{
  height:60px;
  width:160px;
  font-size: 30px;
  margin-top: 20px
}

.save_button{
  margin-top: 20px;
  height:60px;
  background-color: #001d41;
  color:#FFFFFF;
  border:none;
  width: 100%;
}


.bottombar div{
  flex-direction: row;

}

.bottombar div:last-child {
  padding-right: 445px;
}

.bottombar::-webkit-scrollbar {
  display: none;
}



.iphone_menu{
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding-top:70px;
  padding-left:20px;
  padding-right:20px;

  overflow-y:scroll;
  overflow-x:hidden;
  background: rgb(76,76,76); /* Old browsers */
background: -moz-linear-gradient(-45deg, rgba(76,76,76,1) 0%, rgba(89,89,89,1) 12%, rgba(102,102,102,1) 25%, rgba(71,71,71,1) 39%, rgba(44,44,44,1) 50%, rgba(0,0,0,1) 51%, rgba(17,17,17,1) 60%, rgba(43,43,43,1) 76%, rgba(28,28,28,1) 91%, rgba(19,19,19,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, rgba(76,76,76,1) 0%,rgba(89,89,89,1) 12%,rgba(102,102,102,1) 25%,rgba(71,71,71,1) 39%,rgba(44,44,44,1) 50%,rgba(0,0,0,1) 51%,rgba(17,17,17,1) 60%,rgba(43,43,43,1) 76%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, rgba(76,76,76,1) 0%,rgba(89,89,89,1) 12%,rgba(102,102,102,1) 25%,rgba(71,71,71,1) 39%,rgba(44,44,44,1) 50%,rgba(0,0,0,1) 51%,rgba(17,17,17,1) 60%,rgba(43,43,43,1) 76%,rgba(28,28,28,1) 91%,rgba(19,19,19,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.iphone_gml{
  margin: 5px 5px 5px 5px;
}

.iphone_gml .divButton{
  width:56px;
  margin: 2px 2px 2px 2px;
}

.iphone_menu_content{
  display: flex;
  flex-wrap: wrap;
    flex-direction: row;
     flex-grow:0;
     justify-content: center;
     align-content: flex-start
}



.mainWindow{
  margin-left: 80px;
}

.gui_slide_window{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
   -webkit-overflow-scrolling: touch;
   -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  overflow-x: hidden
}
.gui_slide_window svg{
   flex: 0 0 auto;
}

@media screen and (max-width: 600px) {

  body{
    background: rgb(76,76,76); /* Old browsers */
    background-image: url("/bg.png");
      height: 100%;
  }

  .divButton{
    width:40px;
  }
  .mainWindow{
    margin-left: 2px;
  }
  .bottombar div:last-child {
      padding-right: 145px;
  }
}

.version_text{
  font-size: 12px;
  color:#AAAAA;
}

.iframe_fix{
  border: none;
  width:150%;
  height: 150%;
  zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}

@media only screen and (min-width:1100px) and (max-width:4000px) and (min-height: 910px){
  .App{
  zoom: 1.25;
}
.iframe_fix{
  border: none;
  width:150%;
  height: 150%;
  zoom: 0.9375;
  -moz-transform: scale(0.9375);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.9375);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.9375);
  -webkit-transform-origin: 0 0;
}
}

@media only screen and (min-width:1500px) and (max-width:4000px) and (min-height: 1090px){
  .App{
  zoom: 1.5;
}
.iframe_fix{
  border: none;
  width:150%;
  height: 150%;
  zoom: 1.125;
  -moz-transform: scale(1.125);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1.125);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1.125);
  -webkit-transform-origin: 0 0;
}
}
